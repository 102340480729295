import {
  ApolloQueryResult,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { Button, Dialog, Icon, InputGroup, Intent, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { ReactNode, useCallback, useContext, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  BALANCE_ENTRIES,
  CREATE_ENTRY,
  DELETE_ENTRY,
  UPDATE_ENTRY,
} from "../../graphql/mutations/entry";
import {
  CREATE_SPLITTED_ENTRY,
  SOFT_DELETE_SPLITTED_ENTRY,
  UPDATE_SPLITTED_ENTRY,
} from "../../graphql/mutations/splitted_entries";
import { ACCOUNTS_BY_NAME } from "../../graphql/queries/account";
import { CATEGORIES_BY_NAME } from "../../graphql/queries/categories";
import { ENTRIES, ENTRY, FUTURE_ENTRIES, PREVIOUS_ENTRIES } from "../../graphql/queries/entry";
import useSelectedDate from "../../hooks/useSelectedDate";
import { pageTitle } from "../../settings";
import {
  IAccount,
  IBudget,
  ICategory,
  IEntry,
  IPayee,
  ISingleEntry,
  IUser,
} from "../../types/types";
import {
  filterBySearchQuery,
  prepareEntryForInsert,
  prepareSplittedEntries,
  replaceLastOccurrenceInString,
} from "../../utils/budgetUtils";
import { ENTRY_TYPES } from "../../utils/constants";
import { graphQlError, isMobile, parseDate, prepareNewDate } from "../../utils/utils";
import { BudgetContext } from "../WithBudgetContext";
import { UserContext } from "../WithUserContext";
import Responsive from "../common/Responsive";
import SelectedMonthChanger from "../common/SelectedMonthChanger";
import TableHeader from "../common/TableHeader";
import WithLoadingSpinner from "../common/WithLoadingSpinner";
import EntryForm from "../forms/EntryForm";
import HTMLEntriesTable, { TColumns } from "./EntriesTable";

export default function Entries() {
  const {
    selectedDate,
    setCurrentMonthAsSelectedDate,
    setPrevMonthAsSelectedDate,
    setNextMonthAsSelectedDate,
  } = useSelectedDate();

  const {
    data,
    loading,
    error,
    refetch: refetchEntries,
  } = useQuery(ENTRIES, {
    variables: {
      periodStart: selectedDate.startOf("month"),
      periodEnd: selectedDate.endOf("month"),
    },
  });

  error && graphQlError(error);

  const { entries } = data || {};

  return (
    <React.Fragment>
      <Helmet>
        <title>Entries - {pageTitle}</title>
      </Helmet>
      <EntriesTable
        currentPage="entries"
        loading={loading}
        entries={entries}
        columns={["value", "account", "category", "payee", "description", "actions"]}
        selectedDate={selectedDate}
        setCurrentMonthAsSelectedDate={setCurrentMonthAsSelectedDate}
        setPrevMonthAsSelectedDate={setPrevMonthAsSelectedDate}
        setNextMonthAsSelectedDate={setNextMonthAsSelectedDate}
        refetch={refetchEntries}
        initialForm={{}}
      />
    </React.Fragment>
  );
}

export type InitialEntryData = {
  account?: IAccount;
  category?: ICategory;
  payee?: IPayee;
};

export interface IEntriesTableProps {
  entries: IEntry[];
  loading: boolean;
  currentPage: "entries" | "category" | "account" | "payee";
  entrySwitcher?: ReactNode;
  columns: TColumns[];
  queriesToRefetch?: any[];
  enableDrag?: boolean;
  selectedDate: DateTime;
  initialForm: InitialEntryData;
  setCurrentMonthAsSelectedDate: () => void;
  setPrevMonthAsSelectedDate: () => void;
  setNextMonthAsSelectedDate: () => void;
  refetch:
    | ((
        variables?:
          | Partial<{
              periodStart: DateTime;
              periodEnd: DateTime;
            }>
          | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | (() => Promise<[ApolloQueryResult<any>, ApolloQueryResult<any>]>);
}

export function EntriesTable({
  loading,
  currentPage,
  entries,
  entrySwitcher,
  columns,
  enableDrag = false,
  initialForm,
  selectedDate,
  setCurrentMonthAsSelectedDate,
  setPrevMonthAsSelectedDate,
  setNextMonthAsSelectedDate,
  refetch,
}: IEntriesTableProps) {
  const { t } = useTranslation();
  const userData: IUser = useContext(UserContext)!;
  const currentBudget: IBudget = useContext(BudgetContext)!;
  const client = useApolloClient();
  const [searchQuery, setSearchQuery] = useState("");
  const [getPreviousEntries] = useLazyQuery(PREVIOUS_ENTRIES);
  const [getFutureEntries] = useLazyQuery(FUTURE_ENTRIES);
  const [createEntry] = useMutation(CREATE_ENTRY);
  const [updateEntry] = useMutation(UPDATE_ENTRY);
  const [deleteEntry] = useMutation(DELETE_ENTRY);
  const [createSplittedEntry] = useMutation(CREATE_SPLITTED_ENTRY);
  const [updateSplittedEntry] = useMutation(UPDATE_SPLITTED_ENTRY);
  const [deleteSplittedEntry] = useMutation(SOFT_DELETE_SPLITTED_ENTRY);
  const [balanceEntriesMutation] = useMutation(BALANCE_ENTRIES);

  const [selectedDateForEntry, setSelectedDateForEntry] = useState<DateTime | undefined>(undefined);

  const createNewEntry = useCallback(() => {
    const newEntry: Partial<IEntry> = {
      budget_id: currentBudget.id as number,
      user_id: userData.id as string,
      description: "",
      date: prepareNewDate(selectedDateForEntry),
      balance: 0,
      value: 0,
      transfer: false,
      type: ENTRY_TYPES.EXPENSE,
      ..._.omit(initialForm, "category"),
      splitted_entries: initialForm.category
        ? [{ category: initialForm.category, value: 0 } as ISingleEntry]
        : [],
    };

    openForm(newEntry);
  }, [initialForm]);

  const [entry, setEntry] = useState<Partial<IEntry> | null>(null);

  const openForm = (entry: Partial<IEntry>) => {
    setEntry(entry);
  };

  const closeForm = () => setEntry(null);

  const getEntryResponse = (response: {
    data: {
      insert_entries_one?: IEntry;
      update_entries_by_pk?: IEntry;
    };
  }): IEntry | null | undefined =>
    _.get(response, "data.insert_entries_one")
      ? _.get(response, "data.insert_entries_one")
      : _.get(response, "data.update_entries_by_pk")
      ? _.get(response, "data.update_entries_by_pk")
      : null;

  const balanceEntries = (response: IEntry, isDeleting = false): Promise<IEntry> => {
    // console.log("balanceEntries", { response });

    const responseDate = parseDate(response.date);

    const futureEntriesPromise = getFutureEntries({
      variables: {
        periodStart: responseDate,
        accountId: response.account?.id,
      },
    });

    const previousEntriesPromise = getPreviousEntries({
      variables: {
        periodStart: responseDate,
        accountId: response.account?.id,
      },
    });

    return Promise.all([previousEntriesPromise, futureEntriesPromise]).then(
      ([previous, future]) => {
        const entries = _.chain(previous.data.entries.concat(future.data.entries))
          .filter((entry) => entry.entryId !== response.id)
          .concat(isDeleting ? [] : response)
          .uniqBy("id")
          .compact()
          .sortBy("date")
          .value();

        const initialAmount: number =
          _.first(entries).balance ?? response.account?.initial_amount ?? 0;

        if (_.first(entries).balance) {
          entries.shift();
        }

        let diff: number = initialAmount;
        const balancedNewestEntries = _.map(entries, (entryToBalance) => {
          diff =
            entryToBalance.type === ENTRY_TYPES.EXPENSE
              ? diff - entryToBalance.value
              : diff + entryToBalance.value;

          return _.extend(
            {},
            prepareEntryForInsert(
              entryToBalance,
              entryToBalance.account?.id,
              entryToBalance.category?.id
            ),
            {
              balance: diff,
              id: entryToBalance.id,
              budget_id: currentBudget?.id,
              user_id: userData?.user_id,
            }
          );
        });

        return balanceEntriesMutation({ variables: { objects: balancedNewestEntries } }).then(
          () => {
            return Promise.resolve(response);
          }
        );
      }
    );
  };

  const maybeUpdateOrCreateRelatedEntry = (originalEntry: IEntry) => {
    // console.log({ originalEntry });

    if (
      originalEntry.category &&
      originalEntry.category?.name.toLowerCase().indexOf("transfer") !== -1
    ) {
      const categoryName = `Transfer ${
        originalEntry.category?.name.toLowerCase().indexOf("from") !== -1 ? "to" : "from"
      }: ${originalEntry.account?.name} account`;

      const targetAccountName = _.trim(
        //@ts-ignore
        replaceLastOccurrenceInString(originalEntry.category?.name.split(":")[1], "account", "")
      );

      // console.log({ categoryName, targetAccountName });

      return (
        Promise.all([
          client.query({
            query: CATEGORIES_BY_NAME,
            variables: {
              categoryName,
              budgetId: currentBudget.id,
            },
          }),
          client.query({
            query: ACCOUNTS_BY_NAME,
            variables: {
              accountName: targetAccountName,
              budgetId: currentBudget.id,
            },
          }),
        ])
          .then(([categoriesByName, accountsByName]) => {
            const targetCategory = _.first(categoriesByName.data.categories);
            const targetAccount = _.first(accountsByName.data.accounts);

            // console.log({ targetCategory, targetAccount });

            //@ts-ignore
            return { targetCategoryId: targetCategory.id, targetAccountId: targetAccount.id };
          })
          //@ts-ignore
          .then(({ targetCategoryId, targetAccountId }) => {
            return _.isNil(originalEntry.related_entry)
              ? Promise.resolve({
                  ...prepareEntryForInsert(originalEntry, targetAccountId, targetCategoryId),
                  budget_id: currentBudget.id,
                  user_id: userData.user_id,
                  type:
                    originalEntry.type === ENTRY_TYPES.EXPENSE
                      ? ENTRY_TYPES.INCOME
                      : ENTRY_TYPES.EXPENSE,
                })
              : client.query({
                  query: ENTRY,
                  variables: {
                    entryId: originalEntry.related_entry,
                  },
                });
          })
          //@ts-ignore
          .then((relatedEntry) => {
            return _.isNil(originalEntry.related_entry)
              ? createEntry({
                  variables: {
                    object: relatedEntry,
                  },
                })
                  .then((res) => {
                    return balanceEntries(res.data.insert_entries_one);
                  })
                  .then((relatedEntry) => {
                    // UPDATES RELATED ENTRY ID IN ORIGINAL ENTRY
                    return updateEntry({
                      variables: {
                        ...prepareEntryForInsert(
                          originalEntry,
                          originalEntry.account?.id,
                          originalEntry.category?.id
                        ),
                        id: originalEntry.entryId,
                        related_entry: relatedEntry.entryId,
                      },
                    });
                  })
              : updateEntry({
                  variables: {
                    //@ts-ignore
                    ..._.omit(relatedEntry.data.entries_by_pk, [
                      "__typename",
                      "category",
                      "entryId",
                      "account",
                      "payee",
                      "payees",
                    ]),
                    //@ts-ignore
                    id: relatedEntry.data.entries_by_pk.id,
                    //@ts-ignore
                    category_id: relatedEntry.data.entries_by_pk.category.id,
                    //@ts-ignore
                    account_id: relatedEntry.data.entries_by_pk.account.id,
                    //@ts-ignore
                    payee_id: relatedEntry.data.entries_by_pk.payee?.id,
                    value: originalEntry.value,
                    description: originalEntry.description,
                    date: originalEntry.date,
                  },
                }).then((res) => {
                  return balanceEntries(res.data.update_entries_by_pk);
                });
          })
      );
    } else {
      return Promise.resolve(originalEntry as IEntry);
    }
  };

  const onSave = (entry: IEntry) => {
    const tmpEntry = {
      ..._.omit(entry, [
        "id",
        "entryId",
        "__typename",
        "category",
        "account",
        "payee",
        "payees",
        "splitted_entries",
      ]),
      // category_id: entry.category?.id,
      account_id: entry.account?.id,
      id: entry.entryId,
      category_id: entry.category?.id,
      payee_id: entry.payee?.id,
      budget_id: currentBudget.id,
      user_id: userData.user_id,
      type: entry.category?.name.indexOf("Transfer from") === 0 ? ENTRY_TYPES.INCOME : entry.type,
      value: entry.transfer
        ? entry.value
        : _.reduce(
            entry.splitted_entries,
            // @ts-ignore
            (sum: number, entry) => (sum = +sum + +(entry.deleted ? 0 : entry.value)),
            0
          ),
    };

    return (
      (
        !entry.entryId
          ? createEntry({
              variables: {
                object: tmpEntry,
              },
            })
          : updateEntry({
              variables: tmpEntry,
            })
      ) //@ts-ignore
        .then(getEntryResponse)
        //@ts-ignore
        .then(balanceEntries)
        //@ts-ignore
        .then(maybeUpdateOrCreateRelatedEntry)
        //@ts-ignore
        .then((res) => {
          if (_.isEmpty(entry.splitted_entries)) {
            return Promise.resolve();
          } else {
            return Promise.all(
              _.map(
                //@ts-ignore
                prepareSplittedEntries(entry.splitted_entries, userData?.user_id, res.id),
                (splitted_entry) => {
                  if (splitted_entry.deleted) {
                    return deleteSplittedEntry({
                      variables: { id: splitted_entry.id, deleted_at: selectedDate },
                    });
                  } else if (splitted_entry.id) {
                    return updateSplittedEntry({
                      variables: {
                        id: splitted_entry.id,
                        changes: splitted_entry,
                      },
                    });
                  } else {
                    return createSplittedEntry({
                      variables: {
                        object: splitted_entry,
                      },
                    });
                  }
                }
              )
            );
          }
        })
        //@ts-ignore
        .then(() => refetch())
        .then(closeForm)
    );
  };

  const onChange = (key: string, value: any): void => {
    if (key === "date") {
      setSelectedDateForEntry(value);
    }
    setEntry(_.extend({}, entry, { [key]: value }));
  };

  const onDelete = (entry: IEntry) => {
    deleteEntry({
      variables: {
        id: entry.entryId,
      },
    })
      .then((res) => balanceEntries(res.data.delete_entries_by_pk as IEntry, true))
      //@ts-ignore
      .then(() => refetch());
  };

  const onEdit = (entry: IEntry) => {
    setEntry(entry);
  };

  return (
    <>
      <Dialog
        usePortal
        onClose={closeForm}
        isOpen={!_.isEmpty(entry)}
        title={entry?.id ? t("entries.edit_entry") : t("entries.add_entry")}
      >
        <EntryForm
          entry={entry as IEntry}
          onApply={onSave}
          onChange={onChange}
          onCancel={closeForm}
        />
      </Dialog>
      {isMobile() && (
        <Icon
          className="add-entry-mobile-button"
          icon={IconNames.ADD}
          onClick={createNewEntry}
          intent={Intent.PRIMARY}
          size={36}
        />
      )}
      <div className="entries-dashboard-header">
        <TableHeader
          leftElement={
            <>
              {entrySwitcher && <div className="mr-2 w-40">{entrySwitcher}</div>}
              <div className="pr-2 md:pr-14">
                <SelectedMonthChanger
                  setCurrentMonthAsSelectedDate={setCurrentMonthAsSelectedDate}
                  setPrevMonthAsSelectedDate={setPrevMonthAsSelectedDate}
                  setNextMonthAsSelectedDate={setNextMonthAsSelectedDate}
                  selectedDate={selectedDate}
                  vertical={currentPage !== "entries"}
                />
              </div>
              <Responsive displayIn={["Tablet", "Laptop"]}>
                <div className="w-64 pr-6">
                  <InputGroup
                    fill
                    placeholder={t("placeholders.search")}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                    rightElement={<Icon className="mt-2 mr-2" icon={IconNames.SEARCH} />}
                  />
                </div>
              </Responsive>
            </>
          }
          rightElement={
            !isMobile() ? (
              <Button
                className="mr-2"
                text={t("actions.add_entry")}
                icon={IconNames.ADD}
                onClick={createNewEntry}
              />
            ) : (
              <span />
            )
          }
        />
      </div>
      <div className="entries-table-scrollable">
        {_.isEmpty(entries) ? (
          <NonIdealState
            icon="search"
            title={t("messages.you_dont_have_any_entries")}
            className="h-auto my-5"
            action={
              <Button
                text={t("actions.add_entry")}
                icon={IconNames.ADD}
                intent={Intent.PRIMARY}
                onClick={createNewEntry}
              />
            }
          />
        ) : (
          <>
            <WithLoadingSpinner isLoading={loading && _.isEmpty(entries)}>
              <DndProvider backend={HTML5Backend}>
                <HTMLEntriesTable
                  currentMonthEntries={filterBySearchQuery(entries, searchQuery)}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  columns={columns}
                  updateInBackground={onSave}
                  enableDrag={enableDrag}
                  refetch={refetch}
                />
              </DndProvider>
            </WithLoadingSpinner>
          </>
        )}
      </div>
    </>
  );
}
