import { Callout, Card, Collapse, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import accountSelector from "../assets/images/account-selector.png";
import addNewCategory from "../assets/images/add-new-category.png";
import sectionEntry from "../assets/images/add-new-section-entry.png";
import addNewSection from "../assets/images/add-new-section.png";
import budgetEntries from "../assets/images/budget-entries.png";
import budgetWithEntry from "../assets/images/budget-with-entry.png";
import categoryList from "../assets/images/category-list.png";
import createBudgetImage from "../assets/images/create-budget.png";
import createNewAccount from "../assets/images/create-new-account.png";
import dateSelector from "../assets/images/date-selector.png";
import emptyAccounts from "../assets/images/empty-accounts.png";
import emptyBudget from "../assets/images/empty-budget.png";
import emptySection from "../assets/images/empty-section.png";
import entryTable from "../assets/images/entry-table.png";
import filledEntryFrom from "../assets/images/filled-entry-form.png";
import newEntry from "../assets/images/new-entry.png";
import transferSwitch from "../assets/images/transfer-switch.png";

const Help = () => {
  const { t } = useTranslation();

  const helpContent: Record<string, string> = t("help", { returnObjects: true });

  return (
    <div className="py-5 max-w-7xl mx-auto">
      <Callout title={t("labels.help")}>
        {_.map(helpContent, (__, helpKey) => {
          return (
            <CollapsibleHelpSection key={helpKey} label={t(`help.${helpKey}`)} helpKey={helpKey} />
          );
        })}
      </Callout>
    </div>
  );
};

const CollapsibleHelpSection = ({ label, helpKey }: { label: string; helpKey: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mt-5">
      <Callout className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        <div className="flex flex-row">
          <h3 className={`flex-grow font-semibold text-base`}>{label}</h3>
          <Icon icon={isOpen ? IconNames.DOUBLE_CHEVRON_UP : IconNames.DOUBLE_CHEVRON_DOWN} />
        </div>
      </Callout>
      <Collapse isOpen={isOpen}>
        <Card>
          <HelpComponent helpKey={helpKey} />
        </Card>
      </Collapse>
    </div>
  );
};

const HelpComponent = ({ helpKey }: { helpKey: string }) => {
  switch (helpKey) {
    case "structure":
      return <Structure />;
    case "budget":
      return <Budget />;
    case "account":
      return <Account />;
    case "category":
      return <Category />;
    case "entry":
      return <Entry />;
    case "payee":
      return <Payee />;
    default:
      return null;
  }
};

const Structure = () => (
  <div>
    <Trans i18nKey="help.structureDescription">
      <p>
        Jeden użytkownik może mieć utworzony jeden budżet. Po utworzeniu konta powinieneś zobaczyć
        ekran z przyciskiem do utworzenia prostego budżetu.
        <img className="my-6" src={createBudgetImage} alt="create budget button screen" />
      </p>
      <p>
        Po kliknięciu przycisku budżet zostanie utworzony i zostaniesz przekierowany do strony
        głównej budżetu
        <img className="my-6" src={emptyBudget} alt="empty budget screen" />
      </p>
      <p>Ciąg dalszy w sekcji Budżet</p>
    </Trans>
  </div>
);

const Budget = () => (
  <div>
    <Trans i18nKey="help.budgetDescription">
      <p>
        Budżet składa się z sekcji dodanych przez użytkownika.
        <img className="my-6" src={addNewSection} alt="add new section screen" />
      </p>
      <p>
        Po utworzeniu sekcji (np. Oszczędności)
        <img className="my-6" src={emptySection} alt="empty section" />
        możemy do niej dodawać poszczególne wpisy
        <img className="my-6" src={sectionEntry} alt="section entry" />
      </p>
      <p>
        Ważną rzeczą jest aby odpowiednio pokategoryzować swoje wpisy, ułatwi to śledzenie swoich
        wydatków. Aby stworzyć kategorię można wejść zakładkę kategorie i tam dodać nową kategorię,
        lub utworzyć ją bezpośrednio podczas tworzenia wpisu do budżetu wpisując jej nazwę i
        klikając przycisk 'Utwórz (nazwa-kategorii)'.
        <img className="my-6" src={addNewCategory} alt="add new category" />
        Następnie należy wpisać w jakiej wysokości wydatki planujemy. Przykładowo może to wyglądać
        tak
        <img className="my-6" src={budgetEntries} alt="add new category" />
      </p>
    </Trans>
  </div>
);

const Account = () => (
  <Trans i18nKey="help.accountDescription">
    <div>
      Każdy użytkownik może utworzyć wiele kont, mogą to być np konta w bankach, gotówka, pieniądze
      odkładane na lokatach w akcjach itp.
      <img className="my-6" src={emptyAccounts} alt="empty accounts" />
      Podczas tworzenia konta ważne jest aby podać aktualną wartość początkową, dzięki temu po
      wpisaniu wydatów stan konta będzie się aktualizował na bieżąco.
      <img className="my-6" src={createNewAccount} alt="create new account" />
    </div>
  </Trans>
);

const Category = () => (
  <Trans i18nKey="help.categoryDescription">
    <div>
      <p>
        Wszystkie kategorie które zostały dodane w trakcie tworzenia budżetu są dostępne w zakładce
        kategorie.
        <img className="my-6" src={categoryList} alt="category list" />
        Znajdują się tutaj również kategorie 'specjalne' czyli transfery. Transfery są specjalnymi
        kategoriami dzięki którym możliwe jest przenoszenie pieniędzy pomiędzy kontami.
      </p>
    </div>
  </Trans>
);

const Payee = () => (
  <Trans i18nKey="help.payeeDescription">
    <div>
      <p>
        Płatnicy są po prostu oznaczeniem do kogo pieniądze przepływają, mogą to być np rodzaje
        sklepów (Lidl, Biedronka, Castorama, etc)
      </p>
    </div>
  </Trans>
);

const Entry = () => (
  <Trans i18nKey="help.entryDescription">
    <div>
      <p>
        Poza samym budżetem Wpisy są najważniejszym elementem budżetu. To tu wpisujemy nasze
        miesięczne wydatki. Są one następnie przeliczane i wstawiane do naszego budżetu dzięki czemu
        natychmiast widzimy ile w danym miesiącu już wydaliśmy w danej kategorii.
        <img className="my-6" src={newEntry} alt="new entry" />
        Pola oznaczone gwiazdką są obowiązkowe.
      </p>
      <p>
        Najpierw należy wybrać które konto bierzemy pod uwagę , następnie ustawić datę (domyślnie
        jest to dzień obecny).
        <span className="flex flex-row my-6">
          <span className="block mr-4">
            <img src={accountSelector} alt="account selector" />
          </span>
          <span>
            <img src={dateSelector} alt="date selector" />
          </span>
        </span>
      </p>
      <p>
        Póżniej możemy zaznaczyć czy dana operacja jest transferem pieniędzy.
        <img className="my-6" src={transferSwitch} alt="transfer switcher" /> Spowoduje to
        odfiltowanie z listy wszystkich kategorii które nie są transferami więc łatwiej będzie je
        znaleźć.
      </p>
      <p>Wybieramy czy jest to wydatek czy wpłata na konto.</p>
      <p>
        Wybieramy kategorię do jakiej należy wydatek (można też tworzyć nową kategorię w tym
        formularzu, jednak należy pamiętać aby uwzględnić ją później w budżecie)
      </p>
      <p>(Opcjonalnie) Wybieramy płatnika</p>
      <p>Wprowadzamy wartość</p>
      <p>Możemy również dodać jakiś opis (np, jabłka)</p>
      <img className="my-6" src={filledEntryFrom} alt="filled entry form" />
      <p>Klikamy zapisz</p>
      <p>Teraz widzimy tabelę z naszymi wpisami.</p>
      <img className="my-6" src={entryTable} alt="entry table" />
      <p>Możemy również przejść do widoku konta lub kategori poprzez kliknięcie na nazwę.</p>
      <p>
        W widoku konta możemy sprawdzić aktualny stan konta, co pozwala utrzymać synchronizację
        pomiędzy budżetem a naszym faktycznym stanem gotówki lub konta w banku.
      </p>
      <p>Kolejną rzeczą którą możemy sprawdzić jest widok naszego budżetu</p>
      <img className="my-6" src={budgetWithEntry} alt="entry table" />
      <p>
        Widzimy teraz że nasz wpis wydatków został również uwzględniony w naszym budżecie, dzięki
        czemu będziemy widzieli czy mieścimy się w zaplanowanych wydatkach.
      </p>
    </div>
  </Trans>
);

export default Help;
