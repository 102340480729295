import { useQuery } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogBody,
  DialogFooter,
  Intent,
  MenuItem,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select } from "@blueprintjs/select";
import _ from "lodash";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateCategory } from "../../graphql/hooks/category";
import { CATEGORIES_LIST } from "../../graphql/queries/categories";
import { ICategory } from "../../types/types";
import { getCategoryLabel, graphQlError } from "../../utils/utils";
import { UserContext } from "../WithUserContext";
import WithLoadingSpinner from "./WithLoadingSpinner";

const createItem = (name: string) => {
  return {
    name,
  };
};

const renderCreateItemOption = (
  query: string,
  active: boolean,
  handleClick: React.MouseEventHandler<HTMLElement>
) => {
  return (
    <MenuItem
      icon="add"
      text={`Create "${query}"`}
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );
};
//@ts-ignore
const renderItem = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      key={item.id}
      text={getCategoryLabel(item)}
      onClick={handleClick}
    />
  );
};

interface ICategorySelect {
  isTransfer: boolean;
  includeTransfers: boolean;
  onChange: (category: ICategory) => void;
  initialCategory?: ICategory;
  filterOutCategories: string[];
  filterOutNestedCategories: boolean;
  disabled?: boolean;
}

const CategoriesSelect = ({
  isTransfer,
  includeTransfers,
  onChange,
  initialCategory,
  filterOutCategories,
  filterOutNestedCategories,
  disabled = false,
}: ICategorySelect) => {
  const userData = useContext(UserContext);
  const selectRef = useRef(null);
  const { t } = useTranslation();
  //@ts-ignore
  const currentBudget = _.first(userData?.budgets).id;
  const { data, loading } = useQuery(CATEGORIES_LIST, { variables: { budgetId: currentBudget } });

  const { categories = [] } = data || {};
  const [createCategory] = useCreateCategory();

  const [query, setQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const filterCategories = (query: string, category: ICategory) => {
    return getCategoryLabel(category).toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const [confirmDialogOptions, setConfirmDialogOptions] = useState<{
    open: boolean;
    category: ICategory | null;
  }>({ open: false, category: null });

  const onSelectCategory = (category: ICategory) => {
    if (category.id) {
      setSelectedCategory(category);
      onChange(category);
    } else {
      setConfirmDialogOptions({ open: true, category });
    }
  };

  const createNewCategory = () => {
    createCategory({
      variables: {
        object: {
          name: confirmDialogOptions.category?.name,
          budget_id: currentBudget,
          //@ts-ignore
          user_id: userData?.user_id,
        },
      },
    })
      .then((res) => {
        closeDialog();
        setSelectedCategory(res.data.insert_categories_one);
        return onChange(res.data.insert_categories_one);
      })
      .catch(graphQlError)
      .finally(() => {
        // selectRef.current?.close();
      });
  };

  const closeDialog = useCallback(() => {
    setConfirmDialogOptions({ open: false, category: null });
  }, []);

  //TODO fix filtering categories
  // Now all included categories are filtered out, but there is a bug when you tries to change category
  // the previous one won't show up on the list
  // const categoriesToBeFilteredOut = useMemo(
  //   () => _.filter(filterOutCategories, (categoryId) => categoryId !== selectedCategory?.id),
  //   [selectedCategory, filterOutCategories, filterOutNestedCategories]
  // );

  const sortedCategories = useMemo(() => {
    return _.sortBy(categories, (category) => getCategoryLabel(category));
  }, [categories]);

  const filteredTransfersInOrOut = useMemo(() => {
    return includeTransfers
      ? sortedCategories
      : _.filter(sortedCategories, (category) => {
          const isCategoryTransfer = _.includes(category.name, "Transfer");
          return isTransfer ? isCategoryTransfer : !isCategoryTransfer;
        });
  }, [sortedCategories, isTransfer, includeTransfers]);

  const filterNestedCategories = useMemo(() => {
    return filterOutNestedCategories
      ? _.filter(filteredTransfersInOrOut, (category) => _.isEmpty(category.parentCategory))
      : filteredTransfersInOrOut;
  }, [filteredTransfersInOrOut, filterOutNestedCategories]);

  return (
    <WithLoadingSpinner isLoading={loading}>
      <div>
        <Select
          className="categories-select"
          query={query}
          disabled={disabled}
          popoverRef={selectRef}
          onQueryChange={setQuery}
          createNewItemFromQuery={createItem}
          createNewItemRenderer={renderCreateItemOption}
          itemPredicate={filterCategories}
          items={_.filter(
            filterNestedCategories,
            // @ts-ignore
            (category) => !_.includes(filterOutCategories, category.id)
          )}
          onItemSelect={onSelectCategory}
          itemRenderer={renderItem}
          noResults={<MenuItem disabled={true} text="No results." />}
          popoverProps={{
            className: "categories-select-popover",
          }}
        >
          <Button
            text={getCategoryLabel(selectedCategory)}
            rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
          />
        </Select>
      </div>
      <Dialog
        isOpen={confirmDialogOptions.open}
        title={t("confirm.new_category.title")}
        icon={IconNames.WARNING_SIGN}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
      >
        <DialogBody>
          <div
            dangerouslySetInnerHTML={{
              __html: t("confirm.new_category.message", {
                categoryName: confirmDialogOptions.category?.name,
              }),
            }}
          />
        </DialogBody>
        <DialogFooter
          actions={
            <ButtonGroup>
              <Button intent={Intent.NONE} text={t("actions.cancel")} onClick={closeDialog} />
              <Button
                intent={Intent.PRIMARY}
                text={t("actions.create")}
                onClick={createNewCategory}
              />
            </ButtonGroup>
          }
        />
      </Dialog>
    </WithLoadingSpinner>
  );
};

CategoriesSelect.propTypes = {};

export default CategoriesSelect;
