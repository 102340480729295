import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IEntry, ISplittedEntry } from "../../types/types";
import { prepareEntriesForCategoryChart } from "../entries/entriesUtils";

type Props = {
  data: {
    entries: IEntry[];
    splitted_entries: ISplittedEntry[];
  };
};

const CategoryChart = ({ data }: Props) => {
  const { t } = useTranslation();
  const preparedData = prepareEntriesForCategoryChart(
    data || { entries: [], splitted_entries: [] }
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={preparedData}
        margin={{
          top: 0,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={20}
        barGap={-15}
        maxBarSize={20}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={["auto", "auto"]} />
        <Tooltip />
        <Legend
          verticalAlign="top"
          layout="horizontal"
          formatter={(value) => t(`labels.${value}`)}
        />
        <Bar dataKey="expense" fill="#0C5174" />
        <Bar dataKey="income" fill="#238C2C" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CategoryChart;
