import { useMutation } from "@apollo/client";
import { Button, Collapse, Dialog, Icon, IconSize, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { t } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";
import { default as React, ReactElement, useCallback, useContext, useMemo, useState } from "react";
import {
  CREATE_BUDGET_ENTRY,
  SOFT_DELETE_BUDGET_ENTRY,
  UPDATE_BUDGET_ENTRY,
} from "../../graphql/mutations/budgetEntry";
import { BUDGET_SECTIONS } from "../../graphql/queries/budgetSections";
import { IBudgetEntry, IBudgetSection, IEntry } from "../../types/types";
import { filterBudgetEntriesForCurrentMonth } from "../../utils/budgetUtils";
import { graphQlError, parseDate } from "../../utils/utils";
import { UserContext } from "../WithUserContext";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { Notifications } from "../common/notifications";
import BudgetEntryForm from "../forms/BudgetEntryForm";
import BudgetEntries from "./BudgetEntries";

export default function BudgetSection({
  budgetSection,
  selectedDate,
  onEdit,
  onDelete,
  includedCategories,
}: {
  budgetId: number;
  selectedDate: DateTime;
  budgetSection: IBudgetSection;
  includedCategories: number[];
  onEdit: (budgetSection: IBudgetSection) => void;
  onDelete: (budgetSection: IBudgetSection) => Promise<any>;
}): ReactElement {
  const userData = useContext(UserContext);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { budget_entries } = budgetSection;

  const currentBudget = _.first(userData?.budgets)?.id;

  const newBudgetEntry: Partial<IBudgetEntry> = {
    budget_id: currentBudget as number,
    user_id: userData?.id,
    name: "",
    date: selectedDate,
    budget_section_id: budgetSection.id as number,
  };

  const [isOpen, setIsOpen] = useState(true);

  const [budgetEntry, setBudgetEntry] = useState<IBudgetEntry | null>(null);

  const [createBudgetEntry] = useMutation(CREATE_BUDGET_ENTRY, {
    refetchQueries: [
      {
        query: BUDGET_SECTIONS,
        variables: { budgetId: currentBudget, endOfMonth: selectedDate.endOf("month") },
      },
    ],
  });
  const [updateBudgetEntry] = useMutation(UPDATE_BUDGET_ENTRY);
  const [deleteBudgetEntry] = useMutation(SOFT_DELETE_BUDGET_ENTRY);

  const openForm = useCallback(
    (budgetEntry: Partial<IBudgetEntry>) => setBudgetEntry(budgetEntry as IBudgetEntry),
    []
  );

  const closeForm = useCallback(() => setBudgetEntry(null), []);

  const onChange = (key: string, value: any): void => {
    setBudgetEntry(_.extend({}, budgetEntry, { [key]: value }));
  };

  const onDeleteBudgetEntry = (budgetEntry: IBudgetEntry) => {
    return deleteBudgetEntry({ variables: { ...budgetEntry, deleted_at: selectedDate } })
      .then(() => {
        Notifications &&
          Notifications.show({ message: `${t("messages.removed")}`, intent: Intent.WARNING });
      })
      .catch(graphQlError);
  };

  const onSave = (budgetEntry: IBudgetEntry) => {
    const entry: Partial<IBudgetEntry> = {
      ..._.omit(budgetEntry, ["date", "__typename", "category"]),
      category_id: budgetEntry.category.id,
      budget_id: currentBudget as number,
      user_id: userData?.user_id as string,
      date: budgetEntry.id ? budgetEntry.date : selectedDate,
      budget_section_id: budgetSection.id as number,
    };

    const entryMonth = parseDate(budgetEntry.date).toFormat("M");
    const selectedMonth = selectedDate.toFormat("M");
    return (
      !budgetEntry.id
        ? createBudgetEntry({
            variables: {
              object: entry,
            },
          })
        : selectedMonth !== entryMonth
        ? Promise.all([
            createBudgetEntry({
              variables: {
                object: {
                  ..._.omit(entry, "id", "created_at", "updated_at"),
                  date: selectedDate,
                },
              },
            }),
            deleteBudgetEntry({
              variables: { id: entry.id, deleted_at: selectedDate },
            }),
          ])
        : updateBudgetEntry({
            variables: entry,
          })
    ).finally(closeForm);
  };

  const budgetEntries = useMemo(
    () => filterBudgetEntriesForCurrentMonth(budget_entries, selectedDate),
    [budget_entries, selectedDate]
  );

  return (
    <div>
      <div className="w-full md:w-1/2 mx-auto my-3 md:my-12 flex flex-row items-center">
        <div className="border border-gray-300 border-dashed md:w-1/2"></div>
        <h3
          className={`flex items-center min-w-max mx-2 md:mx-6 text-xl cursor-pointer`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon
            size={IconSize.STANDARD}
            className={`mr-2 cursor-pointer`}
            icon={isOpen ? IconNames.DOUBLE_CHEVRON_UP : IconNames.DOUBLE_CHEVRON_DOWN}
          />
          <span className="break-words" style={{ maxWidth: "calc(100vw - 100px)" }}>
            {budgetSection.section_name}
          </span>
        </h3>
        <div className="border border-gray-300 border-dashed md:w-1/2"></div>
      </div>
      <div className={`px-1 pt-0`}>
        <Dialog usePortal isOpen={!_.isEmpty(budgetEntry)} onClose={closeForm}>
          <BudgetEntryForm
            budgetEntry={budgetEntry as IBudgetEntry}
            onApply={onSave}
            onChange={onChange}
            onCancel={closeForm}
            additionalOptions={{
              category: {
                filterOutCategories: includedCategories,
                filterOutNestedCategories: false,
              },
            }}
          />
        </Dialog>
        <ConfirmDialog
          icon={IconNames.WARNING_SIGN}
          title="Delete section warning"
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: t("warnings.remove_section_confirmation", {
                  sectionName: budgetSection.section_name,
                }),
              }}
            ></div>
          }
          onCancel={() => setDeleteDialogOpen(false)}
          isOpen={deleteDialogOpen}
          onApply={() => {
            onDelete(budgetSection);
            setDeleteDialogOpen(false);
          }}
        />
        <Collapse isOpen={isOpen}>
          <React.Fragment>
            <BudgetEntries
              budgetSectionId={budgetSection.id as number}
              selectedDate={selectedDate}
              budgetEntries={budgetEntries}
              onEdit={setBudgetEntry}
              onDelete={onDeleteBudgetEntry}
            />
            <div className="flex flex-row justify-end">
              <Button
                className="m-2"
                text={`${t("actions.add_new_expense_category")}`}
                intent={Intent.NONE}
                icon={IconNames.ADD}
                onClick={() => openForm(newBudgetEntry)}
              />
              <Button
                className="m-2"
                icon={IconNames.EDIT}
                onClick={() => onEdit(budgetSection)}
                text={`${t("actions.edit_section")}`}
              />
              <Button
                className="m-2"
                icon={IconNames.TRASH}
                intent={Intent.DANGER}
                onClick={() => setDeleteDialogOpen(true)}
                text={`${t("actions.delete_section")}`}
              />
            </div>
          </React.Fragment>
        </Collapse>
      </div>
    </div>
  );
}
