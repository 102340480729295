import { Button, Intent, Menu, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover } from "@blueprintjs/core";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Responsive from "./Responsive";

interface IActions {
  entry: any;
  onEdit: (entry: any) => void;
  onDelete: (arg0: any) => void;
  editDisabled?: boolean;
  deleteDisabled?: boolean;
  className?: string;
}

const Actions: FC<IActions> = ({
  entry,
  onEdit,
  onDelete,
  className,
  editDisabled = false,
  deleteDisabled = false,
}) => {
  const { t } = useTranslation();
  const onEditCb = useCallback(() => {
    onEdit(entry);
  }, [onEdit, entry]);

  return (
    <div>
      <Responsive displayIn={["Laptop", "Tablet"]}>
        <div className="flex flex-row">
          <Button
            minimal
            className="ml-2"
            icon={IconNames.EDIT}
            onClick={onEditCb}
            disabled={editDisabled}
          >
            {t("actions.edit")}
          </Button>
          <Button
            minimal
            className="ml-2"
            disabled={deleteDisabled}
            intent={Intent.DANGER}
            icon={IconNames.TRASH}
            onClick={onDelete}
          >
            {t("actions.delete")}
          </Button>
        </div>
      </Responsive>
      <Responsive displayIn={["Mobile"]}>
        <Popover
          canEscapeKeyClose
          content={
            <Menu>
              <MenuItem
                className="my-6"
                text={t("actions.edit")}
                icon={IconNames.EDIT}
                onClick={onEditCb}
              />
              <MenuItem
                className="my-6"
                intent={Intent.DANGER}
                text={t("actions.delete")}
                icon={IconNames.TRASH}
                onClick={onDelete}
              />
            </Menu>
          }
        >
          <Button minimal icon={IconNames.COG} />
        </Popover>
      </Responsive>
    </div>
  );
};

export default Actions;
