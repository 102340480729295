import { Button, Card, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface IBudgetCell {
  onClick: () => void;
  children: ReactNode;
  emptyBudget?: boolean;
}

const BudgetCell = ({ emptyBudget, children, onClick }: IBudgetCell) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div className="flex flex-col h-full justify-between">
        <div className="flex-grow">{children}</div>
        {!emptyBudget && (
          <div className="mt-10 text-center">
            <Button
              icon={IconNames.MAP_CREATE}
              onClick={onClick}
              intent={Intent.SUCCESS}
              aria-roledescription="create-budget"
            >
              {t("actions.create_budget")}
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default BudgetCell;
