import { Callout } from "@blueprintjs/core";
import { IIncome } from "../../types/types";
import Actions from "../common/Actions";
import MoneyValue from "../common/MoneyValue";

type Props = {
  income: IIncome;
  openDeleteDialog: (income: IIncome) => void;
  onEdit: (arg0: any) => void;
};

const MobileIncome = ({ income, openDeleteDialog, onEdit }: Props) => {
  return (
    <Callout className="my-1 py-2">
      <div className="">
        <div className="flex flex-row items-center">
          <h1 className="text-lg bold flex-grow">{income.name}</h1>
          <Actions entry={income} onDelete={openDeleteDialog} onEdit={onEdit} />
        </div>
        <div className="flex flex-row items-center">
          <div className="flex-grow">
            <span className="text-lg inline-block mr-1">
              <MoneyValue value={income.value} />
            </span>
            <span className="text-lg inline-block mr-1">/</span>
            <span className="text-lg inline-block mr-1">
              <MoneyValue value={income.actual_value} />
            </span>
          </div>
        </div>
      </div>
    </Callout>
  );
};

export default MobileIncome;
