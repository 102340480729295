import { Callout } from "@blueprintjs/core";
import React, { ReactElement } from "react";

interface ITableHeader {
  className?: string;
  leftElement: JSX.Element;
  rightElement: JSX.Element;
  leftElClassName?: string;
  rightElClassName?: string;
}

export default function TableHeader({
  className,
  leftElement,
  rightElement,
  leftElClassName,
  rightElClassName,
}: ITableHeader): ReactElement {
  return (
    <Callout>
      <div className={className ?? "flex flex-grow flex-row items-center"}>
        <div className={leftElClassName ?? "flex-grow flex flex-row items-center"}>
          {leftElement}
        </div>
        <div className={rightElClassName ?? ""}>{rightElement}</div>
      </div>
    </Callout>
  );
}
