import { ApolloQueryResult } from "@apollo/client";
import update from "immutability-helper";
import _ from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect, useReducer } from "react";
import { useDrop } from "react-dnd";
import { IEntry } from "../../types/types";
import { parseDate } from "../../utils/utils";
import Responsive from "../common/Responsive";
import EntryDeleteWarning from "../dialogs/EntryDeleteWarning";
import { EntriesTBody } from "./EntriesTBody";
import EntriesTHead from "./EntriesTHead";
import MobileEntriesList from "./MobileEntriesList";
import { getNewEntryDate } from "./entriesUtils";

export const ItemTypes = {
  ROW: "row",
};

interface ITableState {
  baseGroupHovered: string | undefined;
  currentGroupHovered: string | undefined;
  deleteDialogOpen: boolean;
  draggedEntry: IEntry | undefined;
  entries: IEntry[];
  entryToBeDeleted: IEntry | undefined;
  groupedEntries: { [key: string]: IEntry[] };
}

interface ITableAction {
  type: string;
  payload?: any;
}

const INITIAL_TABLE_STATE: ITableState = {
  baseGroupHovered: undefined,
  currentGroupHovered: undefined,
  deleteDialogOpen: false,
  draggedEntry: undefined,
  entries: [],
  entryToBeDeleted: undefined,
  groupedEntries: {},
};

const tableReducer = (state: ITableState, action: ITableAction): ITableState => {
  switch (action.type) {
    case "SET_ENTRIES":
      return {
        ...state,
        entries: action.payload,
      };
    case "SET_GROUPED_ENTRIES":
      return {
        ...state,
        groupedEntries: action.payload,
      };
    case "OPEN_DELETE_DIALOG":
      return { ...state, deleteDialogOpen: true };
    case "CLOSE_DELETE_DIALOG":
      return { ...state, deleteDialogOpen: false };

    case "SET_ENTRY_TO_BE_DELETED":
      return { ...state, entryToBeDeleted: action.payload };

    case "CLEAR_ENTRY_TO_BE_DELETED":
      return { ...state, entryToBeDeleted: undefined };

    case "BASE_GROUP_HOVERED":
      return { ...state, baseGroupHovered: action.payload };

    case "CURRENT_GROUP_HOVERED":
      return { ...state, currentGroupHovered: action.payload };

    case "SET_DRAGGED_ENTRY":
      return { ...state, draggedEntry: action.payload };

    default:
      return state;
  }
};

export type TColumns =
  | "value"
  | "description"
  | "category"
  | "account"
  | "payee"
  | "balance"
  | "type"
  | "actions";

interface ITableProps {
  currentMonthEntries: IEntry[];
  onDelete: (entry: IEntry) => void;
  onEdit: (entry: IEntry) => void;
  columns: TColumns[];
  updateInBackground: (entry: IEntry) => void;
  enableDrag: boolean;
  refetch:
    | ((
        variables?:
          | Partial<{
              periodStart: DateTime;
              periodEnd: DateTime;
            }>
          | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | (() => Promise<[ApolloQueryResult<any>, ApolloQueryResult<any>]>);
}

const HTMLEntriesTable = ({
  currentMonthEntries,
  onEdit,
  onDelete,
  columns,
  refetch,
  updateInBackground,
  enableDrag,
}: ITableProps) => {
  const [state, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  useEffect(() => {
    dispatch({ type: "SET_ENTRIES", payload: currentMonthEntries });
  }, [currentMonthEntries]);

  useEffect(() => {
    dispatch({
      type: "SET_GROUPED_ENTRIES",
      payload: _.groupBy(currentMonthEntries, ({ date }) => parseDate(date).toFormat("yyyy-MM-dd")),
    });
  }, [currentMonthEntries]);

  const openDeleteDialog = useCallback((entry: IEntry) => {
    dispatch({ type: "OPEN_DELETE_DIALOG" });
    dispatch({ type: "SET_ENTRY_TO_BE_DELETED", payload: entry });
  }, []);

  const closeDeleteDialog = useCallback(() => {
    dispatch({ type: "CLOSE_DELETE_DIALOG" });
    dispatch({ type: "CLEAR_ENTRY_TO_BE_DELETED" });
  }, []);

  const moveEntry = useCallback(
    (item: { index: number; entry: IEntry }, hoverIndex: number, groupIndex: string) => {
      if (!state.currentGroupHovered) {
        dispatch({ type: "BASE_GROUP_HOVERED", payload: groupIndex });
      }

      dispatch({ type: "CURRENT_GROUP_HOVERED", payload: groupIndex });

      if (
        state.baseGroupHovered &&
        state.currentGroupHovered &&
        state.baseGroupHovered !== state.currentGroupHovered
      ) {
        // #1remove from base group
        const entriesFromBaseGroupWithoutDraggedCard = _.filter(
          (state.groupedEntries || {})[state.baseGroupHovered] as IEntry[],
          (entry) => entry.id !== state.draggedEntry?.id
        );

        const payload = _.set(
          state.groupedEntries || {},
          state.baseGroupHovered,
          entriesFromBaseGroupWithoutDraggedCard
        );

        const newEntriesInCurrentGroup = update(state.groupedEntries[state.currentGroupHovered], {
          $splice: [
            [item.index, 1],
            [hoverIndex, 0, item.entry],
          ], // add to current group)
        });

        const newEnt = _.set(payload, state.currentGroupHovered, newEntriesInCurrentGroup);

        dispatch({ type: "SET_GROUPED_ENTRIES", payload: newEnt });
      } else {
        //@ts-ignore
        const tmpEntries = (state.groupedEntries || {})[groupIndex] as IEntry[];
        const newEntries = update(tmpEntries, {
          $splice: [
            [item.index, 1],
            [hoverIndex, 0, item.entry],
          ],
        });
        const newEnt = _.set(state.groupedEntries || {}, groupIndex, newEntries);

        dispatch({ type: "SET_GROUPED_ENTRIES", payload: newEnt });
      }
    },
    [state]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.ROW,
      drop: (item: { index: number; entry: IEntry }) => {
        const { index, entry } = item;

        const nextEntry = index - 1 >= 0 ? state.entries[index - 1] : undefined;
        const prevEntry = index + 1 < state.entries.length ? state.entries[index + 1] : undefined;
        const newEntryDate = getNewEntryDate(
          prevEntry,
          nextEntry,
          state.currentGroupHovered || state.baseGroupHovered
        );

        updateInBackground(_.extend({}, entry, { date: newEntryDate }));
        dispatch({ type: "SET_DRAGGED_ENTRY", payload: undefined });
        dispatch({ type: "CURRENT_GROUP_HOVERED", payload: undefined });
      },
    }),
    [state]
  );

  return (
    <>
      <Responsive displayIn={["Mobile"]}>
        <MobileEntriesList
          entries={currentMonthEntries}
          onEdit={onEdit}
          openDeleteDialog={openDeleteDialog}
        />
      </Responsive>
      <Responsive displayIn={["Tablet", "Laptop"]}>
        <table ref={drop} className="entries-table w-full max-w-full table-fixed">
          <EntriesTHead columns={columns} />
          {_.map(state.groupedEntries, (entries, date) => {
            return (
              <EntriesTBody
                columns={columns}
                date={date}
                enableDrag={enableDrag}
                entries={entries}
                key={date}
                moveEntry={moveEntry}
                onEdit={onEdit}
                openDeleteDialog={openDeleteDialog}
                updateInBackground={updateInBackground}
                refetch={refetch}
              />
            );
          })}
        </table>
        <EntryDeleteWarning
          entryToBeDeleted={state.entryToBeDeleted}
          closeDeleteDialog={closeDeleteDialog}
          deleteDialogOpen={state.deleteDialogOpen}
          onDelete={onDelete}
        />
      </Responsive>
    </>
  );
};

export default HTMLEntriesTable;
