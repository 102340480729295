import gql from "graphql-tag";

export const BUDGET_ENTRY_FRAGMENT = gql`
  fragment BudgetEntryFragment on budget_entries {
    id
    name
    value
    date
    created_at
    deleted_at
    category {
      id
      name
      entries {
        value
        type
        date
      }
      splitted_entries(where: { deleted_at: { _is_null: true } }) {
        id
        value
        entry {
          date
          type
        }
      }
    }
  }
`;

export const BUDGET_ENTRIES = gql`
  ${BUDGET_ENTRY_FRAGMENT}
  query BudgetEntries($parentBudgetSectionId: Int) {
    budget_entries: budget_entries(
      where: { budget_section_id: { _eq: $parentBudgetSectionId } }
      order_by: { value: desc }
    ) {
      ...BudgetEntryFragment
    }
  }
`;
