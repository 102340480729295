import { useMutation, useQuery } from "@apollo/client";
import { Button, Dialog, HTMLTable, Intent, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { useCallback, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  CREATE_ESTIMATE,
  DELETE_ESTIMATE,
  UPDATE_ESTIMATE,
} from "../../graphql/mutations/estimates";
import { ESTIMATES } from "../../graphql/queries/estimates";
import { pageTitle } from "../../settings";
import { IEstimate, IEstimateEntry } from "../../types/types";
import { UserContext } from "../WithUserContext";
import Actions from "../common/Actions";
import { ConfirmDialog } from "../common/ConfirmDialog";
import InternalLink from "../common/InternalLink";
import TableHeader from "../common/TableHeader";
import WithLoadingSpinner from "../common/WithLoadingSpinner";
import EstimateForm from "../forms/EstimateForm";

const Estimates = () => {
  const { t } = useTranslation();
  const userData = useContext(UserContext);
  const { data, loading, error } = useQuery(ESTIMATES);

  const [estimateEntry, setEstimateEntry] = useState<IEstimate | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [entryToBeDeleted, setEntryToBeDeleted] = useState<IEstimate | null>(null);
  const createNewEstimate = useCallback(() => setEstimateEntry({ name: "" }), []);
  const closeDialog = useCallback(() => setEstimateEntry(null), []);

  const onChange = (key: string, value: any): void => {
    setEstimateEntry(_.extend({}, estimateEntry, { [key]: value }));
  };

  const [createEstimate] = useMutation(CREATE_ESTIMATE, {
    refetchQueries: [{ query: ESTIMATES }],
  });

  const [updateEstimate] = useMutation(UPDATE_ESTIMATE);

  const [deleteEstimate] = useMutation(DELETE_ESTIMATE, {
    refetchQueries: [{ query: ESTIMATES }],
  });

  const onDelete = (estimateId: number) => deleteEstimate({ variables: { id: estimateId } });

  const onSave = (estimate: IEstimate) => {
    return (
      !estimate.id
        ? createEstimate({
            variables: {
              object: {
                ..._.omit(estimate, ["__typename"]),
                user_id: userData?.user_id,
              },
            },
          })
        : updateEstimate({
            variables: {
              ..._.omit(estimate, ["__typename"]),
            },
          })
    ).then(() => closeDialog());
  };

  const onEdit = (estimate: IEstimate) => {
    setEstimateEntry(estimate);
  };

  const openDeleteDialog = useCallback((entry: IEstimateEntry) => {
    setDeleteDialogOpen(true);
    setEntryToBeDeleted(entry);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false);
    setEntryToBeDeleted(null);
  }, []);

  return (
    <WithLoadingSpinner isLoading={loading}>
      <Helmet>
        <title>Estimates - {pageTitle}</title>
      </Helmet>
      <Dialog
        usePortal
        isOpen={!_.isEmpty(estimateEntry)}
        onClose={closeDialog}
        title={
          estimateEntry?.id
            ? t("actions.edit_estimate", { name: estimateEntry?.name })
            : t("actions.add_estimate")
        }
      >
        <EstimateForm
          estimate={estimateEntry as IEstimate}
          onChange={onChange}
          onApply={onSave}
          onCancel={closeDialog}
        />
      </Dialog>
      <ConfirmDialog
        icon={IconNames.WARNING_SIGN}
        title={t("warnings.delete_estimate")}
        message={
          <div
            dangerouslySetInnerHTML={{
              __html: t("warnings.remove_estimate_confirmation", { name: entryToBeDeleted?.name }),
            }}
          />
        }
        onCancel={closeDeleteDialog}
        isOpen={deleteDialogOpen && !_.isEmpty(entryToBeDeleted)}
        onApply={() => {
          onDelete(entryToBeDeleted?.id as number);
          closeDeleteDialog();
        }}
      />
      {_.isEmpty(data?.estimates) ? (
        <NonIdealState
          icon="search"
          title={t("messages.you_dont_have_any_estimates")}
          action={
            <Button
              text={t("actions.add_estimate")}
              intent={Intent.PRIMARY}
              onClick={createNewEstimate}
            />
          }
        />
      ) : (
        <div>
          <TableHeader
            rightElement={
              <Button
                icon={IconNames.ADD}
                text={t("actions.add_estimate")}
                onClick={createNewEstimate}
              />
            }
            leftElement={<div></div>}
          />
          <HTMLTable
            bordered
            compact
            striped
            className="entries-table w-full max-w-full table-fixed dark:text-gray-100"
          >
            <thead>
              <tr>
                <th className="budget-table-name">{t("labels.name")}</th>
                <th className="budget-table-actions" />
              </tr>
            </thead>
            <tbody>
              {_.map(data?.estimates, (estimate) => {
                return (
                  <tr className="budget-entry" key={estimate.id}>
                    <td>
                      <InternalLink to={`/estimates/${estimate.id}`}>{estimate.name}</InternalLink>
                    </td>
                    <td className="budget-table-actions">
                      <Actions
                        entry={estimate}
                        onDelete={() => openDeleteDialog(estimate)}
                        onEdit={() => onEdit(estimate)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </HTMLTable>
        </div>
      )}
    </WithLoadingSpinner>
  );
};

export default Estimates;
