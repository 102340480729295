import { useAuth0 } from "@auth0/auth0-react";
import { pageTitle, trialDaysCount } from "../settings";

import ContentWithFooter from "./layouts/ContentWithFooter";
import { DollarIcon, SubscriptionCard, SubscriptionTitle } from "./Subscriptions";
import { Trans, useTranslation } from "react-i18next";
import { Button, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Helmet } from "react-helmet";

const Pricing = () => {
  const { t } = useTranslation();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  return (
    <ContentWithFooter>
      <Helmet>
        <title>Pricing - {pageTitle}</title>
      </Helmet>
      <div className="mx-auto text-base">
        <div className="flex flex-col md:flex-row">
          <SubscriptionCard className="flex flex-grow">
            <div className="flex flex-col h-full">
              <div
                className="flex flex-row items-center text-green-700 text-7xl justify-center"
                style={{ textShadow: "3px 3px 3px #aaa" }}
              >
                <strong>&#9752;</strong>
              </div>
              <SubscriptionTitle title={t("subscriptions.free.title", { dayNo: trialDaysCount })} />
              <Trans i18nKey="subscriptions.free.description">
                <div className="text-left md:px-5">
                  <div>
                    <strong>Try It Free: Unlock Full Functionality for 14 Days!</strong>
                  </div>
                  Experience the full power of our home budget application with a 14-day free trial.
                  Enjoy all premium features and tools to help you master your finances, set savings
                  goals, and gain financial clarity. No commitment, no risk — just smart budgeting
                  at your fingertips. Start your free trial today and take the first step towards
                  financial freedom!
                  <div className="py-6 text-center">
                    <strong>No credit card required!</strong>
                  </div>
                </div>
              </Trans>

              <div className="mt-auto">
                {!isAuthenticated && (
                  <Button
                    rightIcon={<Icon className="ml-2" icon={IconNames.CHART} size={40} />}
                    className="mt-6 text-xl md:text-xl px-6 py-3 md:px-16 md:py-3 rounded shadowtext-lg font-semibold mx-auto hover:opacity-75 flex flex-row items-center"
                    onClick={() => loginWithRedirect()}
                    intent={Intent.PRIMARY}
                  >
                    {t("actions.start")}
                  </Button>
                )}
              </div>
            </div>
          </SubscriptionCard>
          <SubscriptionCard className="flex flex-grow">
            <div className="flex flex-col h-full">
              <DollarIcon value={1} />
              <SubscriptionTitle title={t("subscriptions.one_month_subscription.title")} />
              <Trans i18nKey="subscriptions.one_month_subscription.description">
                <div className="text-left md:px-5">
                  Unlock the full potential of your finances with our one-month subscription! Gain
                  instant access to powerful budgeting tools, detailed spending insights, and
                  personalized financial advice, all designed to help you take control of your
                  money. Here's what you get:
                  <ul className="my-4">
                    <li className="py-2">
                      <strong>Comprehensive Budgeting Tools:</strong> Easily create and manage
                      budgets that suit your lifestyle and goals.
                    </li>
                    <li className="py-2">
                      <strong>Detailed Spending Reports:</strong> Understand your spending habits
                      with in-depth analysis and reports.
                    </li>
                    <li className="py-2">
                      <strong>Savings Goals:</strong> Set and track your savings goals to ensure
                      you're always on the right path.
                    </li>
                    <li className="py-2">
                      <strong>Real-Time Updates:</strong> Keep track of your finances
                    </li>
                    <li className="py-2">
                      <strong>Secure and Private:</strong> Your financial data is protected with the
                      highest security standards.
                    </li>
                    <li className="py-2">
                      <strong>Customer Support:</strong> Enjoy priority customer support to help you
                      with any questions or issues.
                    </li>
                  </ul>
                  Join today and start your journey towards financial freedom. Simplify your
                  budgeting, save more, and achieve your financial goals with our one-month
                  subscription!
                </div>
              </Trans>
              <div className="mt-auto">
                {!isAuthenticated && (
                  <Button
                    rightIcon={<Icon className="ml-2" icon={IconNames.CHART} size={40} />}
                    className="mt-6 text-xl md:text-xl px-6 py-3 md:px-16 md:py-3 rounded shadowtext-lg font-semibold mx-auto hover:opacity-75 flex flex-row items-center"
                    onClick={() => loginWithRedirect()}
                    intent={Intent.PRIMARY}
                  >
                    {t("actions.start")}
                  </Button>
                )}
              </div>
            </div>
          </SubscriptionCard>
          <SubscriptionCard className="flex flex-grow">
            <div className="flex flex-col h-full">
              <DollarIcon value={10} />
              <SubscriptionTitle title={t("subscriptions.one_year_subscription.title")} />
              <Trans i18nKey="subscriptions.one_year_subscription.description">
                <div className="text-left md:px-5">
                  Unlock the full potential of your finances with our one-year subscription! Gain
                  instant access to powerful budgeting tools, detailed spending insights, and
                  personalized financial advice, all designed to help you take control of your
                  money. Here's what you get:
                  <ul className="my-4">
                    <li className="py-2">
                      <strong>Comprehensive Budgeting Tools:</strong> Easily create and manage
                      budgets that suit your lifestyle and goals.
                    </li>
                    <li className="py-2">
                      <strong>Detailed Spending Reports:</strong> Understand your spending habits
                      with in-depth analysis and reports.
                    </li>
                    <li className="py-2">
                      <strong>Savings Goals:</strong> Set and track your savings goals to ensure
                      you're always on the right path.
                    </li>
                    <li className="py-2">
                      <strong>Real-Time Updates:</strong> Keep track of your finances
                    </li>
                    <li className="py-2">
                      <strong>Secure and Private:</strong> Your financial data is protected with the
                      highest security standards.
                    </li>
                    <li className="py-2">
                      <strong>Customer Support:</strong> Enjoy priority customer support to help you
                      with any questions or issues.
                    </li>
                  </ul>
                  Join today and start your journey towards financial freedom. Simplify your
                  budgeting, save more, and achieve your financial goals with our one-year
                  subscription!
                </div>
              </Trans>
              <div className="mt-auto">
                {!isAuthenticated && (
                  <Button
                    rightIcon={<Icon className="ml-2" icon={IconNames.CHART} size={40} />}
                    className="mt-6 text-xl md:text-xl px-6 py-3 md:px-16 md:py-3 rounded shadowtext-lg font-semibold mx-auto hover:opacity-75 flex flex-row items-center"
                    onClick={() => loginWithRedirect()}
                    intent={Intent.PRIMARY}
                  >
                    {t("actions.start")}
                  </Button>
                )}
              </div>
            </div>
          </SubscriptionCard>
        </div>
      </div>
    </ContentWithFooter>
  );
};

export default Pricing;
