import { useMutation, useQuery } from "@apollo/client";
import { Button, Dialog, HTMLTable, Intent, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { t } from "i18next";
import _ from "lodash";
import { useCallback, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { CREATE_PAYEE, DELETE_PAYEE, UPDATE_PAYEE } from "../../graphql/mutations/payee";
import { PAYEES } from "../../graphql/queries/payees";
import useSelectedDate from "../../hooks/useSelectedDate";
import { pageTitle } from "../../settings";
import { IPayee } from "../../types/types";
import { UserContext } from "../WithUserContext";
import Actions from "../common/Actions";
import { ConfirmDialog } from "../common/ConfirmDialog";
import DateComponent from "../common/Date";
import GraphQlError from "../common/GraphQlError";
import InternalLink from "../common/InternalLink";
import MoneyValue from "../common/MoneyValue";
import TableHeader from "../common/TableHeader";
import WithLoadingSpinner from "../common/WithLoadingSpinner";
import PayeeForm from "../forms/PayeeForm";

export default function Payees() {
  const { t } = useTranslation();
  const userData = useContext(UserContext);

  //@ts-ignore
  const { data, loading, error } = useQuery(PAYEES);
  const [createPayee] = useMutation(CREATE_PAYEE, {
    refetchQueries: [{ query: PAYEES }],
  });

  const [updatePayee] = useMutation(UPDATE_PAYEE, {
    refetchQueries: [{ query: PAYEES }],
  });
  const [deletePayee] = useMutation(DELETE_PAYEE, { refetchQueries: [{ query: PAYEES }] });
  const [payeeEntry, setPayeeEntry] = useState<IPayee | null>(null);

  const onEdit = (payee: IPayee) => setPayeeEntry(payee);
  const onDelete = (payeeId: number) => deletePayee({ variables: { id: payeeId } });
  const createNewPayee = () => setPayeeEntry({ name: "" });
  const closeDialog = () => setPayeeEntry(null);

  const onSave = (payee: IPayee) => {
    return (
      !payee.id
        ? createPayee({
            variables: {
              object: {
                ..._.omit(payee, ["__typename"]),
                //@ts-ignore
                user_id: userData?.user_id,
              },
            },
          })
        : updatePayee({
            variables: {
              ..._.omit(payee, ["__typename"]),
              //@ts-ignore
              user_id: userData?.user_id,
            },
          })
    ).then(closeDialog);
  };

  const onChange = (key: string, value: any): void => {
    setPayeeEntry(_.extend({}, payeeEntry, { [key]: value }));
  };

  const { payees = [] } = data || {};

  return (
    <WithLoadingSpinner isLoading={loading}>
      <Helmet>
        <title>Payees - {pageTitle}</title>
      </Helmet>
      <GraphQlError error={error}>
        <Dialog
          usePortal
          isOpen={!_.isEmpty(payeeEntry)}
          onClose={closeDialog}
          title={
            payeeEntry?.id
              ? t("actions.edit_payee", { name: payeeEntry?.name })
              : t("actions.add_payee")
          }
        >
          <PayeeForm
            payee={payeeEntry as IPayee}
            onChange={onChange}
            onApply={onSave}
            onCancel={closeDialog}
          />
        </Dialog>
        {_.isEmpty(payees) ? (
          <NonIdealState
            icon={IconNames.SEARCH}
            title={t("messages.you_dont_have_any_payee")}
            action={
              <Button
                text={t("actions.add_payee")}
                intent={Intent.PRIMARY}
                onClick={createNewPayee}
              />
            }
          />
        ) : (
          <div>
            <TableHeader
              rightElement={
                <Button
                  icon={IconNames.ADD}
                  text={t("actions.add_payee")}
                  onClick={createNewPayee}
                />
              }
              leftElement={<div></div>}
            />

            <HTMLTable
              bordered
              compact
              striped
              className="entries-table w-full max-w-full table-fixed dark:text-gray-100"
            >
              <thead>
                <tr>
                  <th>{t("labels.payee")}</th>
                  <th className="budget-table-actions" />
                </tr>
              </thead>
              <tbody>
                {_.map(payees, (payee) => (
                  <Payee key={payee.id} payee={payee} onEdit={onEdit} onDelete={onDelete} />
                ))}
              </tbody>
            </HTMLTable>
          </div>
        )}
      </GraphQlError>
    </WithLoadingSpinner>
  );
}

export const Payee = ({
  payee,
  onEdit,
  onDelete,
}: {
  payee: IPayee;
  onEdit: (payee: IPayee) => void;
  onDelete: (id: number) => void;
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { selectedDate } = useSelectedDate();
  const dateParam = selectedDate.toFormat("yyyy-MM-dd");
  const openDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(true);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false);
  }, []);

  return (
    <tr key={payee.id}>
      <td>
        <InternalLink to={`/payees/${payee.id}`}>{payee.name}</InternalLink>
      </td>
      <td className="budget-table-actions">
        <Actions entry={payee} onEdit={() => onEdit(payee)} onDelete={openDeleteDialog} />
        <ConfirmDialog
          icon={IconNames.WARNING_SIGN}
          title={t("warnings.delete_payee_warning") as string}
          message={
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("warnings.remove_payee_confirmation", { name: payee.name }),
                }}
              />
              <div className="mt-2">
                {t("warnings.following_entries_will_be_removed") as string}
              </div>
              <div>
                {_.map(payee.entries, (entry) => {
                  return (
                    <div key={entry.id} className="flex flex-row">
                      <div className="mr-2">
                        <DateComponent date={entry.date} />
                      </div>
                      <div className="mr-2">
                        <MoneyValue value={entry.value} />
                      </div>
                      <div>{entry.category?.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          }
          onCancel={closeDeleteDialog}
          isOpen={deleteDialogOpen}
          onApply={() => {
            onDelete(payee.id as number);
            closeDeleteDialog();
          }}
        />
      </td>
    </tr>
  );
};
