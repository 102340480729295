import { useQuery } from "@apollo/client";
import { Icon, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { ReactElement, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { GOAL, GOALS_LIST } from "../../graphql/queries/goal";
import useSelectedDate from "../../hooks/useSelectedDate";
import { IAccount, IUser } from "../../types/types";
import { UserContext } from "../WithUserContext";
import Responsive from "../common/Responsive";
import WithLoadingSpinner from "../common/WithLoadingSpinner";
import { EntriesTable } from "../entries/Entries";
import { TColumns } from "../entries/EntriesTable";
import AccountChart from "./GoalChart";
import InternalLink from "../common/InternalLink";

export default function Account(): ReactElement {
  const { goalId } = useParams<{ goalId: string }>();
  const {
    selectedDate,
    setCurrentMonthAsSelectedDate,
    setPrevMonthAsSelectedDate,
    setNextMonthAsSelectedDate,
  } = useSelectedDate();
  const userData = useContext<IUser | undefined>(UserContext);
  const { data, loading, error, refetch } = useQuery(GOAL, {
    variables: {
      accountId: goalId && parseInt(goalId),
      periodStart: selectedDate.startOf("month"),
      periodEnd: selectedDate.endOf("month"),
    },
  });

  const { accounts_by_pk: account } = data || {};

  const columns: TColumns[] = ["value", "category", "payee", "description", "balance", "actions"];

  return (
    <>
      <div className="mb-20">
        <EntriesTable
          loading={loading}
          currentPage="account"
          columns={
            userData?.settings["showBalanceOnAccountEntryTable"]
              ? columns
              : columns.filter((col) => col !== "balance")
          }
          entries={account?.entries}
          entrySwitcher={<AccountsSelect currentAccount={account} />}
          enableDrag
          selectedDate={selectedDate}
          setCurrentMonthAsSelectedDate={setCurrentMonthAsSelectedDate}
          setPrevMonthAsSelectedDate={setPrevMonthAsSelectedDate}
          setNextMonthAsSelectedDate={setNextMonthAsSelectedDate}
          refetch={refetch}
          initialForm={{
            account,
          }}
        />
      </div>
      <Responsive displayIn={["Laptop"]}>
        {!_.isEmpty(account?.entries) && <AccountChart entries={account?.entries} />}
      </Responsive>
    </>
  );
}

const AccountsSelect = ({ currentAccount }: { currentAccount: IAccount }) => {
  const { data, loading: loadingAccounts } = useQuery(GOALS_LIST);

  const { accounts } = data || {};
  return (
    <WithLoadingSpinner isLoading={loadingAccounts}>
      <Popover
        position={Position.BOTTOM_LEFT}
        content={
          <Menu>
            {_.map(accounts || [], (acc) => (
              <MenuItem
                icon={IconNames.CREDIT_CARD}
                key={acc.id}
                tagName="div"
                text={<InternalLink to={`/goals/${acc.id}`}>{acc.name}</InternalLink>}
              />
            ))}
          </Menu>
        }
      >
        <div className="flex flex-row items-center cursor-pointer">
          <Icon icon={IconNames.CREDIT_CARD} />{" "}
          <div className="mx-2 line-clamp-2">{currentAccount?.name} </div>
          <Icon icon={IconNames.CHEVRON_DOWN} />
        </div>
      </Popover>
    </WithLoadingSpinner>
  );
};
