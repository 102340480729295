import { ApolloError } from "@apollo/client";
import { Callout, H2, Intent } from "@blueprintjs/core";
import _ from "lodash";
import { ReactNode } from "react";

type Props = {
  error: ApolloError | undefined;
  children: ReactNode;
};

const GraphQlError = ({ error, children }: Props) => {
  return _.isEmpty(error) ? (
    <>{children}</>
  ) : (
    <Callout intent={Intent.WARNING}>
      <H2>{error?.name}</H2>
      <code>{error?.message}</code>
      <code>{JSON.stringify(error?.extraInfo)}</code>
    </Callout>
  );
};

export default GraphQlError;
