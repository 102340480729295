import { useQuery } from "@apollo/client";
import { Icon, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { ESTIMATES } from "../../graphql/queries/estimates";
import { IEstimate } from "../../types/types";
import InternalLink from "../common/InternalLink";
import WithLoadingSpinner from "../common/WithLoadingSpinner";

const EstimateSelect = ({ currentEstimate }: { currentEstimate: IEstimate }) => {
  const { data, loading: loadingAccounts } = useQuery(ESTIMATES);

  const { estimates } = data || {};
  return (
    <WithLoadingSpinner isLoading={loadingAccounts}>
      <Popover
        position={Position.BOTTOM_LEFT}
        content={
          <Menu>
            {_.map(estimates || [], (estimate) => (
              <MenuItem
                icon={IconNames.CREDIT_CARD}
                key={estimate.id}
                tagName="div"
                text={<InternalLink to={`/estimates/${estimate.id}`}>{estimate.name}</InternalLink>}
              />
            ))}
          </Menu>
        }
      >
        <div className="flex flex-row items-center cursor-pointer">
          <Icon icon={IconNames.CREDIT_CARD} />{" "}
          <div className="mx-2 line-clamp-2">{currentEstimate?.name} </div>
          <Icon icon={IconNames.CHEVRON_DOWN} />
        </div>
      </Popover>
    </WithLoadingSpinner>
  );
};

export default EstimateSelect;
