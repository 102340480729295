import React, { FC } from "react";
import TableHeader from "../common/TableHeader";
import SelectedMonthChanger from "../common/SelectedMonthChanger";
import { DateTime } from "luxon";

interface IBudgetHeaderProps {
  setCurrentMonthAsSelectedDate: () => void;
  setPrevMonthAsSelectedDate: () => void;
  setNextMonthAsSelectedDate: () => void;
  selectedDate: DateTime;
}

const BudgetHeader: FC<IBudgetHeaderProps> = ({
  setCurrentMonthAsSelectedDate,
  setPrevMonthAsSelectedDate,
  setNextMonthAsSelectedDate,
  selectedDate,
}) => {
  return (
    <TableHeader
      leftElement={
        <SelectedMonthChanger
          setCurrentMonthAsSelectedDate={setCurrentMonthAsSelectedDate}
          setPrevMonthAsSelectedDate={setPrevMonthAsSelectedDate}
          setNextMonthAsSelectedDate={setNextMonthAsSelectedDate}
          selectedDate={selectedDate}
          vertical={false}
        />
      }
      rightElement={<div></div>}
    />
  );
};

export default BudgetHeader;
