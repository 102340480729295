import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogBody,
  DialogFooter,
  Intent,
  MenuItem,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select } from "@blueprintjs/select";
import _ from "lodash";
import { MouseEventHandler, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CREATE_PAYEE } from "../../graphql/mutations/payee";
import { PAYEE_LIST, PAYEES } from "../../graphql/queries/payees";
import { IPayee } from "../../types/types";
import { graphQlError } from "../../utils/utils";
import { UserContext } from "../WithUserContext";
import WithLoadingSpinner from "./WithLoadingSpinner";

const createItem = (name: string) => {
  return {
    name,
  };
};

const renderCreateItemOption = (
  query: string,
  active: boolean,
  handleClick: MouseEventHandler<HTMLElement>
) => {
  return (
    <MenuItem
      icon="add"
      text={`Create "${query}"`}
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );
};
//@ts-ignore
const renderItem = (item: IPayee, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem active={modifiers.active} key={item.id} text={item.name} onClick={handleClick} />
  );
};

interface IPayeeSelect {
  disabled: boolean;
  onChange: (arg: IPayee) => void;
  initialPayee: IPayee;
  fill?: boolean;
}

const PayeesSelect = ({ fill = false, disabled, onChange, initialPayee }: IPayeeSelect) => {
  const userData = useContext(UserContext);
  const userId = userData?.user_id;
  const { t } = useTranslation();
  const { loading, error, data: payees } = useQuery(PAYEE_LIST);

  const [query, setQuery] = useState("");
  const [selectedPayee, setSelectedPayee] = useState<IPayee | null>(initialPayee);

  const [createPayee] = useMutation(CREATE_PAYEE, {
    refetchQueries: [{ query: PAYEES }],
  });

  const filterPayees = (query: string, payee: IPayee) => {
    return payee.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const [confirmDialogOptions, setConfirmDialogOptions] = useState<{
    open: boolean;
    payee: IPayee | null;
  }>({ open: false, payee: null });

  const createNewPayee = () => {
    createPayee({
      variables: {
        object: {
          ..._.omit(confirmDialogOptions.payee, ["__typename"]),
          user_id: userId,
        },
      },
    })
      .then((res) => {
        setSelectedPayee(res.data.insert_payees_one);
        onChange(res.data.insert_payees_one);
      })
      .catch(graphQlError);
  };

  const onSelectPayee = (payee: IPayee) => {
    if (payee.id) {
      setSelectedPayee(payee);
      onChange(payee);
    } else {
      setConfirmDialogOptions({ open: true, payee });
    }
  };

  const closeDialog = () => {
    setConfirmDialogOptions({ open: false, payee: null });
  };

  return (
    <WithLoadingSpinner isLoading={loading}>
      <div className="w-full">
        <Select
          fill={fill}
          disabled={disabled}
          query={query}
          onQueryChange={setQuery}
          createNewItemFromQuery={createItem}
          createNewItemRenderer={renderCreateItemOption}
          itemPredicate={filterPayees}
          items={payees?.payees}
          onItemSelect={onSelectPayee}
          itemRenderer={renderItem}
          noResults={<MenuItem disabled={true} text="No results." />}
        >
          <Button fill={fill} text={selectedPayee?.name} rightIcon="double-caret-vertical" />
        </Select>
      </div>
      <Dialog
        isOpen={confirmDialogOptions.open}
        title={t("confirm.new_payee.title")}
        icon={IconNames.WARNING_SIGN}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
      >
        <DialogBody>
          <div
            dangerouslySetInnerHTML={{
              __html: t("confirm.new_payee.message", {
                payeeName: confirmDialogOptions.payee?.name,
              }),
            }}
          />
        </DialogBody>
        <DialogFooter
          actions={
            <ButtonGroup>
              <Button intent={Intent.NONE} text={t("actions.cancel")} onClick={closeDialog} />
              <Button intent={Intent.PRIMARY} text={t("actions.create")} onClick={createNewPayee} />
            </ButtonGroup>
          }
        />
      </Dialog>
    </WithLoadingSpinner>
  );
};

export default PayeesSelect;
