import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { ReactNode } from "react";

interface IValueWithEditMode {
  value: (() => ReactNode) | ReactNode;
  edit: (() => ReactNode) | ReactNode;
  editing: boolean;
  onAccept: () => void;
  onCancel: () => void;
}

const ValueWithEditMode = ({ value, edit, editing, onAccept, onCancel }: IValueWithEditMode) => {
  return (
    <div className="value-with-edit-mode">
      {editing ? (
        <div className="flex flex-row relative">
          <div className="value-with-edit-mode--edit-mode">
            {_.isFunction(edit) ? edit() : edit}
          </div>
          <div className="flex flex-row absolute right-0 top-2">
            <Icon aria-label="accept-icon" icon={IconNames.TICK} onClick={onAccept} />
            <Icon aria-label="cancel-icon" icon={IconNames.CROSS} onClick={onCancel} />
          </div>
        </div>
      ) : (
        <div className="value-with-edit-mode--read-mode">
          {_.isFunction(value) ? value() : value}
        </div>
      )}
    </div>
  );
};

export default ValueWithEditMode;
