import { Button, ButtonGroup, Divider } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DateTime } from "luxon";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "../../utils/utils";

interface ISelectedMonthChangerProps {
  setCurrentMonthAsSelectedDate: () => void;
  setPrevMonthAsSelectedDate: () => void;
  setNextMonthAsSelectedDate: () => void;
  selectedDate: DateTime;
  vertical?: boolean;
}

const SelectedMonthChanger: FC<ISelectedMonthChangerProps> = ({
  setCurrentMonthAsSelectedDate,
  setPrevMonthAsSelectedDate,
  setNextMonthAsSelectedDate,
  selectedDate,
  vertical = true,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonGroup className="month-changer" minimal vertical={isMobile() && vertical}>
      <Button
        className="text-xs md:text-sm dark:text-gray-100"
        text={t("labels.current_month")}
        icon={IconNames.CALENDAR}
        onClick={setCurrentMonthAsSelectedDate}
      />
      <Divider />
      <ButtonGroup minimal vertical={false}>
        <Button
          className="month-changer-button-prev text-xs md:text-sm dark:text-gray-100"
          icon={IconNames.DOUBLE_CHEVRON_LEFT}
          onClick={setPrevMonthAsSelectedDate}
        />
        <Button
          className="month-changer-button-current md:w-32 text-xs md:text-sm dark:text-gray-100 text-center"
          text={selectedDate.toFormat("MMMM yyyy")}
        />
        <Button
          className="month-changer-button-next text-xs md:text-sm dark:text-gray-100"
          icon={IconNames.DOUBLE_CHEVRON_RIGHT}
          onClick={setNextMonthAsSelectedDate}
        />
      </ButtonGroup>
    </ButtonGroup>
  );
};

export default SelectedMonthChanger;
