import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Browser } from "@capacitor/browser";
import { useTranslation } from "react-i18next";
import { mobileRedirectUri } from "../../settings";
import { App as CapApp } from "@capacitor/app";
import { ConfirmDialog } from "../common/ConfirmDialog";
import React from "react";
import _ from "lodash";

const MobileLogoutBtn = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const [exitAppConfirm, setExitAppConfirm] = React.useState(false);
  const doLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: mobileRedirectUri,
      },
      async openUrl(url) {
        // Redirect using Capacitor's Browser plugin
        await Browser.open({
          url,
          windowName: "_self",
        });
        CapApp.exitApp();
      },
    });
  };

  return (
    <React.Fragment>
      <Button
        minimal
        className="text-2xl md:text-base"
        onClick={() => setExitAppConfirm(true)}
        text={t("labels.logout")}
        icon={IconNames.LOG_OUT}
      />
      <ConfirmDialog
        icon={IconNames.WARNING_SIGN}
        title={t("warnings.logout_and_exit_app_title")}
        message={
          <div
            dangerouslySetInnerHTML={{
              __html: t("warnings.logout_and_exit_app"),
            }}
          />
        }
        onCancel={() => setExitAppConfirm(false)}
        isOpen={exitAppConfirm}
        onApply={() => doLogout()}
      />
    </React.Fragment>
  );
};

export default MobileLogoutBtn;
