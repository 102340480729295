import { Callout } from "@blueprintjs/core";
import _ from "lodash";
import { IEntry } from "../../types/types";
import Actions from "../common/Actions";
import DateComponent from "../common/Date";
import InternalLink from "../common/InternalLink";
import MoneyValue from "../common/MoneyValue";

interface IMobileEntriesList {
  entries: IEntry[];
  onEdit: (entry: IEntry) => void;
  openDeleteDialog: (entry: IEntry) => void;
}

const MobileEntriesList = ({ entries, openDeleteDialog, onEdit }: IMobileEntriesList) => {
  return (
    <div>
      {_.map(entries, (entry) => (
        <Entry key={entry.id} entry={entry} openDeleteDialog={openDeleteDialog} onEdit={onEdit} />
      ))}
    </div>
  );
};

interface IEntryProps {
  entry: IEntry;
  onEdit: (entry: IEntry) => void;
  openDeleteDialog: (entry: IEntry) => void;
}

const Entry = ({ entry, onEdit, openDeleteDialog }: IEntryProps) => {
  return (
    <Callout className={`mt-1 mb-3 budget-table__row--${entry.type}`}>
      <div>
        <div className={`flex flex-row items-center`}>
          <div className="flex-grow">
            <MoneyValue value={entry.value} />
            <div className="mt-2 text-xs">
              {entry.category?.name ? (
                <InternalLink to={`/categories/${entry.category?.id}`}>
                  {entry.category?.name}
                </InternalLink>
              ) : (
                _.map(entry.splitted_entries, (splitted_entry) => {
                  return (
                    <div key={splitted_entry.id} className="flex flex-row items-center">
                      <MoneyValue value={Number(splitted_entry.value) || 0} />
                      <span className="inline-block ml-2">{splitted_entry.category?.name}</span>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className="mr-2">
            <DateComponent className="text-xs" date={entry.date} />
          </div>
          <Actions entry={entry} onDelete={() => openDeleteDialog(entry)} onEdit={onEdit} />
        </div>
        {!_.isEmpty(entry.description) ? (
          <div className="text-xs italic mt-1">{entry.description}</div>
        ) : null}
      </div>
    </Callout>
  );
};

export default MobileEntriesList;
