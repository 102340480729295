import { Spinner, SpinnerSize } from "@blueprintjs/core";
import { ReactNode } from "react";

interface IWithLoadingSpinner {
  isLoading: boolean;
  children: ReactNode;
}

const WithLoadingSpinner = ({ isLoading, children }: IWithLoadingSpinner) => {
  return isLoading ? <Spinner size={SpinnerSize.SMALL} /> : <>{children}</>;
};

export default WithLoadingSpinner;
